import { produce } from 'immer';
import { UPDATE_LOADING_STATE, UPDATE_TIMEZONE } from '../../actions/ActionTypes/commonConfigTypes';

export interface CommonConfigState {
  isCstTimeZone: boolean
  isDarkMode: boolean
  dataLoadingCount: number
  timeZone: string | null
}

export interface CommonConfigActionState {
  type: string
  timeZone: string | null
  loadingCount: number
  
}

export function setInitValue (): boolean {
  if (
    localStorage.getItem('isTimeZoneDefined') === '' ||
        localStorage.getItem('isTimeZoneDefined') === null
  ) {
    localStorage.setItem('isTimeZoneDefined', 'true');
    return true;
  } else if (localStorage.getItem('isTimeZoneDefined') === 'false') {
    return false;
  } else {
    return true;
  }
}

const initialState: CommonConfigState = {
  isCstTimeZone: setInitValue(),
  isDarkMode: false,
  dataLoadingCount: 0,
  timeZone: 'CST'
};

const commonConfigReducer = (
  state = initialState,
  action: CommonConfigActionState
): CommonConfigState =>
  produce(state, (draft: CommonConfigState) => {
    switch (action.type) {
      case UPDATE_TIMEZONE:
        draft.timeZone = action.timeZone;
        draft.isCstTimeZone = action.timeZone==="CST"?true:false;
        break;
      case UPDATE_LOADING_STATE:
        draft.dataLoadingCount += action.loadingCount;
        break;
    }
  });

export default commonConfigReducer;
