import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ExpandOutlined, CompressOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import Tabs from '../../components/common/Tabs';
import SearchTabComponent from '../../components/AdditionalComponents/LogException/LogExceptionSearch/SearchTabComponent';
import textConstants from '../../constants/textConstants';
import { PageTitleWrapper, MainContentWrapper, DefaultSmallButton, FullScreenButton } from './style';
import { createNewSearchTabByType, removeSearchTabByType } from '../../helpers/searchTabHelper';
import { changeLogActiveTab } from '../../redux/actions/LogSearch/logSearchActions';
import { changeExceptionActiveTab } from '../../redux/actions/ExceptionSearch/exceptionSearchActions';
import { SEARCH_TAB_MAX_LIMIT } from '../../constants/commonConstants';
import { confirmToast } from '../../components/common/Toast';

interface SearchProps {
  applicationList: any
  activeTab: string
  categoryList?: any[]
  severityList?: any[]
  fetchAppplicationList: () => void
  fetchExceptionCategory?: any
  fetchExceptionSeverity?: any
  fetchExceptionType?: any
  userAccessInfo?: any
  logExceptionType: string
  searchTabList: any[]
  timeZone: string
  typeList?: any[]
}

const LogExceptionSearch: FC<SearchProps> = ({
  activeTab,
  applicationList,
  categoryList,
  fetchAppplicationList,
  fetchExceptionCategory,
  fetchExceptionSeverity,
  fetchExceptionType,
  logExceptionType,
  severityList,
  timeZone,
  typeList,
  userAccessInfo,
  searchTabList
}) => {
  const dispatch = useDispatch();
  const [isFullScreenView, setFullScreenView] = useState<boolean>();

  useEffect(() => {
    if (searchTabList.length === 0) {
      createNewSearchTabByType(logExceptionType);
    }
  }, [searchTabList])
  const onSearchTabEventHandler = (targetKey: any, actionType: 'add' | 'remove'): void => {
    if (actionType === 'add') {
      createNewSearchTabByType(logExceptionType);
    }
    if (actionType === 'remove') {
      const okHandlerFunc = (): void => {
        removeSearchTabByType(logExceptionType, targetKey);
      }
      confirmToast(textConstants.DISCARD_TAB_CONFIRM_MSG, '', okHandlerFunc);
    }
  };

  return (
    <MainContentWrapper className={isFullScreenView ? 'fullscreen-mode' : ''}>
      <Tabs
        defaultActiveTab="DefaultSearch"
        tabSize="small"
        tabType="editable-card"
        tabClassName={'basic-card-theme tab-wrapper-custom-position ' + (searchTabList.length === SEARCH_TAB_MAX_LIMIT ? 'disable-new-tab-add-btn-class' : '')}
        tabItems={
          (searchTabList)?.map((tabItem: any) => {
            return {
              ...tabItem,
              label:
                <>
                  {tabItem.label}
                  {
                    !isFullScreenView &&
                    <FullScreenButton data-testid='fullScreenViewBtn' onClick={(e) => { e.stopPropagation(); setFullScreenView(true) }}>
                      <ExpandOutlined />
                    </FullScreenButton>
                  }
                </>,
              children: (
                <SearchTabComponent
                  applicationList={applicationList}
                  categoryList={categoryList}
                  typeList={typeList}
                  userAccessInfo={userAccessInfo}
                  fetchAppplicationList={fetchAppplicationList}
                  fetchExceptionCategory={fetchExceptionCategory}
                  fetchExceptionSeverity={fetchExceptionSeverity}
                  fetchExceptionType={fetchExceptionType}
                  logExceptionType={logExceptionType}
                  severityList={severityList}
                  timeZone={timeZone}
                  activeTabName={activeTab}
                />
              )
            };
          })}
        onTabEditHandler={(targetKey: any, action: 'add' | 'remove') =>
          onSearchTabEventHandler(targetKey, action)
        }
        activeTab={activeTab}
        onTabChangeHandler={(activeKey) => {
          logExceptionType.toLocaleLowerCase().toString() === 'log' ? dispatch(changeLogActiveTab(activeKey)) : dispatch(changeExceptionActiveTab(activeKey));
        }}
        animated
        destroyInactiveTab
      />
      <PageTitleWrapper isException={logExceptionType === 'Exception'}>
        <h1>
          {logExceptionType === 'Log' ? textConstants.LOG_SEARCH_PAGE_TITLE : textConstants.EXCEPTION_SEARCH_PAGE_TITLE}
        </h1>
        {
          isFullScreenView &&
          <>
            <DefaultSmallButton
              data-testid='test-id-close-fullscreen-btn'
              onClick={() => {
                setFullScreenView(false);
              }}
              size='small'
            >
              <CompressOutlined />
            </DefaultSmallButton>
          </>
        }
      </PageTitleWrapper>
    </MainContentWrapper>
  );
};

LogExceptionSearch.propTypes = {
  applicationList: PropTypes.array,
  categoryList: PropTypes.array,
  severityList: PropTypes.array,
  fetchAppplicationList: PropTypes.func.isRequired,
  fetchExceptionCategory: PropTypes.func,
  fetchExceptionSeverity: PropTypes.func,
  fetchExceptionType: PropTypes.func,
  logExceptionType: PropTypes.string.isRequired,
  userAccessInfo: PropTypes.object,
  timeZone: PropTypes.string.isRequired,
  typeList: PropTypes.array
};

export default LogExceptionSearch;
