import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Space, Switch } from 'antd';
import { useOktaAuth } from '@okta/okta-react';
import { UserOutlined, ClockCircleOutlined, DownOutlined } from '@ant-design/icons';
import ConfirmationPopup from '../../common/ConfirmationPopup';
import { updateTimeZone } from '../../../redux/actions/CommonConfig/commonConfigActions';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';
import { UserAvatar, UserSettingsMenuWrapper } from './style';
import UserProfileDrawer from '../../AdditionalComponents/Drawer/UserProfileDrawer';
import { TimeDropdownArrowWrapper } from '../style';

const userMenuItems = [
  {
    label: 'Profile',
    key: 'userProfile'
  },
  {
    label: 'Logout',
    key: 'logout'
  }
];

const timeMenuItems = [
  {
    label: textConstants.TIMEZONE_LOCAL_TIME_LABEL,
    key: 'local-time'
  },
  {
    label: textConstants.TIMEZONE_CST_LABEL,
    key: 'CST'
  },
  {
    label: 'GMT',
    key: 'GMT'
  }
];

const UserSettingsMenu: FC = () => {
  const dispatch = useDispatch();
  const timeZone = useSelector((state: any) => state.commonConfig?.timeZone);
  const userDetails = useSelector((state: any) => state.userAccess.userAccessInfo);

  const [isTimeZoneDisabled, setTimeZoneDisableState] = useState<boolean>(true);
  const [isConfirmLogout, setLogoutConfirmState] = useState<boolean>(false);
  const [isUserProfileDrawerOpen, setUserProfileDrawerVisibility] = useState<boolean>(false);
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  console.log(timeZone, '===time')

  /**
   * Method to handle User Logout.
   */
  const handleLogout = async (): Promise<void> => {
    await oktaAuth.signOut();
    setLogoutConfirmState(false);
    sessionStorage.clear();
    localStorage.clear();
  };

  /**
   * Method to handle User Dropdown Menu Item Click.
   * @param {event} evt
   */
  const handleUserMenuClick = (evt: any): void => {
    switch (evt.key) {
    case 'logout':
      setLogoutConfirmState(true);
      break;
    case 'userProfile':
      setUserProfileDrawerVisibility(true);
      break;
    }
  };

  /**
   * Method to handle timeZone switch toggle.
   */
  const handleTimezoneToggle = (): void => {
    if (
      localStorage.getItem('isTimeZoneDefined') === '' ||
      localStorage.getItem('isTimeZoneDefined') === 'false'
    ) {
      localStorage.setItem('isTimeZoneDefined', 'true');
    } else {
      localStorage.setItem('isTimeZoneDefined', 'false');
    }
  };

  useEffect(() => {
    const isTimeZoneDisable = !(history.location.pathname === routeConstants.LOG_SEARCH ||
      history.location.pathname === '/' ||
      history.location.pathname === routeConstants.EXCEPTION_SEARCH ||
      history.location.pathname === routeConstants.DASHBOARD);

    setTimeZoneDisableState(isTimeZoneDisable);
  }, []);

  const handleTimeMenuChange = (evt: any): void => {
    // console.log(evt, '--evt');
    // console.log(evt.key);
    handleTimezoneToggle();
    dispatch(updateTimeZone(evt.key));
  }

  return (
    <UserSettingsMenuWrapper>
      <Space>
        <Dropdown
          disabled={isTimeZoneDisabled}
          overlayClassName='time-menu'
          menu={{ items: timeMenuItems, onClick: handleTimeMenuChange, selectable: true, defaultSelectedKeys: [], selectedKeys: [timeZone] }}
          trigger={['click']}

        >
          <span className="timezone-toggle-wrapper mr-10">
            <span><ClockCircleOutlined /> {textConstants.TIMEZONE_TIME_LABEL} :&nbsp;
              {timeZone?.includes('local-time') ? 'Local' : timeZone}
              <TimeDropdownArrowWrapper><DownOutlined /></TimeDropdownArrowWrapper>
            </span>
          </span>
        </Dropdown>
        {/* <span className="timezone-toggle-wrapper mr-10">
          <span><ClockCircleOutlined /> {textConstants.TIMEZONE_TIME_LABEL}</span>
          <Switch
            id="timezone"
            onChange={(checked) => {
              handleTimezoneToggle();
              dispatch(updateTimeZone(checked));
            }}
            checkedChildren={textConstants.TIMEZONE_CST_LABEL}
            unCheckedChildren={textConstants.TIMEZONE_LOCAL_TIME_LABEL}
            checked={timeZone}
            disabled={isTimeZoneDisabled}
          />
        </span> */}
        <Dropdown
          overlayClassName='user-menu'
          menu={{ items: userMenuItems, onClick: handleUserMenuClick }}
          trigger={['click']}
        >
          <UserAvatar
            className="cursor-pointer"
            icon={<UserOutlined />}
            data-testid="userProfile"
          />
        </Dropdown>
      </Space>
      {

      }
      {isConfirmLogout && (
        <ConfirmationPopup
          isConfirmOpen={isConfirmLogout}
          message={textConstants.LOGOUT_MESSAGE}
          onConfirmHandler={async () => await handleLogout()}
          onCancelHandler={() => setLogoutConfirmState(false)}
        />
      )}
      {isUserProfileDrawerOpen && (
        <UserProfileDrawer
          isOpen={isUserProfileDrawerOpen}
          data={userDetails}
          onClose={() => setUserProfileDrawerVisibility(false)}
        />
      )}
    </UserSettingsMenuWrapper>
  );
};

export default UserSettingsMenu;
