import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html {
        font-size: var(--font-size-lg);
    }

    html, body {
        height: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
    }
    
    body {
        margin: 0;
        padding: 0;
        background: var(--white);
        font-family: var(--primary-font-family);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-regular);
    }

    [class*=" ant-form"] {
        font-size: var(--font-size-sm);
    }

    [class^="ant-layout"] {
        font-size: var(--font-size-md);
    }

    #root {
        min-height: 100vh;
        background: var(--main-content-wrapper-bg);
    }
    // Antd Tags
    .ant-tag{
        margin: 2px;
        font-size: var(--font-size-md);
    }


    // Antd Switch Style
    .ant-switch {
        background: var(--switch-off-bg);
        height: 24px;
        line-height: 24px;

        .ant-switch-handle {
            top: 3px;
        }

        .ant-switch-inner {
            padding-inline-start: 24px;
            padding-inline-end: 6px;

            & .ant-switch-inner-checked, & .ant-switch-inner-unchecked {
                font-size: var(--font-size-xs);
                font-weight: var(--font-weight-medium);
            }

            & .ant-switch-inner-unchecked {
                margin-top: -24px;
            }
        }
        
        &.ant-switch-checked {
            background: var(--switch-on-bg);

            .ant-switch-inner {
                padding-inline-start: 6px;
                padding-inline-end: 24px;
            }

            &:hover:not(.ant-switch-disabled) {
                background: var(--switch-on-bg);
            }
        }
    }

    // Antd Dropdown Style
    .ant-dropdown {
        min-width: 100px;
        .ant-dropdown-menu {
            padding: 0;

            .ant-dropdown-menu-item {
                border-radius: 0;
            }
        }
    }
    
    .ant-select-dropdown {
        border-radius: 0;

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background: var(--blue-color-100);
            color: var(--white);
            margin-bottom: 2px;

            .ant-select-item-option-state {
                color: var(--white);
            }
        }

        .tag-dropdown-menu {
            .ant-select-item-option.ant-select-item-option-selected {
                display: none;
            }

            .ant-select-item-empty {
                font-size: var(--font-size-sm);
            }
        }
    }

    // Antd Checkbox
    .ant-checkbox-wrapper {
        color: var(--form-label-text-color);

        &:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
            background-color: var(--white);
        }

        &.ant-checkbox-wrapper-disabled:not(.ant-checkbox-wrapper-checked) {
            .ant-checkbox .ant-checkbox-inner {
                background-color: var(--gray-175);
                border-color: var(--gray-350);
            }
        }

        &.ant-checkbox-wrapper-checked {
            .ant-checkbox-disabled + span {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        .ant-checkbox .ant-checkbox-inner {
            border-color: var(--gray-450);
        }
        
        .ant-checkbox
        .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
            border-color: var(--blue-color-100);
            background-color: var(--white);
        }

        .ant-checkbox-checked {
            &:after {
                border-color: var(--form-field-checkbox-border-color);
                border-width: 1px;
            }

            .ant-checkbox-inner {
                border-color: var(--form-field-checkbox-border-color);
                background-color: var(--white);
                &:after {
                    border-color: var(--pepsi-primary-color);
                    top: 42%;
                }
            }
        }
    }

    .user-menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
        font-size: var(--font-size-md);
        color: var(--black-100);
        height: 30px;
        line-height: 30px;
        min-width: 150px;

        @media screen and (min-width: 1600px) {
            height: 35px;
            line-height: 35px;
        }
    }
    .ant-dropdown-trigger{
        cursor: pointer;
    }
    .ant-dropdown-trigger[disabled]{
        cursor: not-allowed;
    }
    .time-menu.ant-dropdown .ant-dropdown-menu{
        padding:5px 2px;
        top: 15px;
        .ant-dropdown-menu-item-active .ant-dropdown-menu-item-selected{
            border-radius:8px;
        }
    }
    .time-menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
        font-size: var(--font-size-md);
        color: var(--black-100);
        line-height: 30px;
        height:32px;
        min-width: 150px;
        border-radius: 8px;
        color: var(--gray-800);
        @media screen and (min-width: 1600px) {
            height: 35px;
            line-height: 35px;
        }
    }

    .ant-menu-submenu-popup {
        &.ant-menu-submenu-placement-bottomLeft {
            .ant-menu-vertical {
                position: relative;
                top: 16px;
                padding: 0 0 10px;
                left: -10px;
            }
        }

        .ant-menu-sub {
            box-shadow: 0px 3px 6px var(--black-50);
        }

        .ant-menu-vertical > .ant-menu-item {
            margin: 0;
            width: 100%;
            border-radius: 0;
            font-size: var(--font-size-md);
            color: var(--black-100);
            height: 30px;
            line-height: 30px;

            @media screen and (min-width: 1600px) {
                height: 38px;
                line-height: 38px;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    .ant-select, .ant-input-number, .ant-picker, .ant-input, .react-dropdown-select {
        width: 100%;
    }

    .ant-picker {
        &-focused {
            box-shadow: none;
        }
    }

    .ant-input-number {
        .ant-input-number-input-wrap, .ant-input-number-input {
            height: 100%;
        }
    }

    // Antd Select, Input, InputNumber & MultiSelect Common Style
    .ant-select,.ant-input-number,.form-lg .ant-picker,.ant-input, .ant-input-affix-wrapper.ant-input-password{
        border-color: var(--form-field-border-color);
        font-size: var(--font-size-sm);
        height: 40px;

        @media screen and (min-width: 1920px) {
            height: 48px;
        }

        &-sm {
            height: 32px;

            .ant-input-number-input-wrap {
                line-height: 32px;
            }
        }

        &:hover {
            border-color: var(--form-field-focus-border-color);
        }

        &:focus, &-focused, &-focused:not(.ant-select-customize-input) .ant-select-selector, :focus-within {
            box-shadow: none!important;
            border-color: var(--form-field-focus-border-color);
        }
    }

    .ant-input-password, .ant-input-number {
        .ant-input {
            height: auto;
        }
    }

    .ant-picker {
        height: auto;

        & &-input > input {
            font-size: var(--font-size-sm);
        }
    }

    .form-lg .ant-picker {
        border-color: var(--gray-275);
    }

    .ant-select, .ant-select-sm {
        height: 100%;

        &-dropdown .ant-select-item {
            font-size: var(--font-size-sm);
        }
    }

    .ant-select-single {
        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
            height: 100%;
            font-size: var(--font-size-sm);
        }

        .ant-select-selector {
            font-size: var(--font-size-sm);

            .ant-select-selection-item {
                line-height: 40px;
                @media screen and (min-width: 1920px) {
                    line-height: 48px;
                }
            }
        }
    }

    .ant-form-item-control-input {
        .ant-select-single,.ant-select-single, .ant-select-show-search.ant-select  {
            &:not(.ant-select-customize-input) {
                .ant-select-selector {
                    border-color: var(--form-field-border-color);
                    min-height: 40px;

                    @media screen and (min-width: 1920px) {
                        min-height: 48px;
                    }
                }

                &.ant-select-status-error .ant-select-selector {
                    border-color: var(--red-50);
                }

                .ant-select-arrow {
                    color: var(--blue-color);
                }
            }
        }
    }

    .ant-select-single.ant-select-sm  {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-color: var(--form-field-border-color);
                min-height: 32px;
                font-size: var(--font-size-sm);
                line-height: 1;
            }

            .ant-select-selection-item {
                height: 100%:
                line-height: 32px;
            }
        }

        .ant-select-selector .ant-select-selection-item {
            line-height: 32px;
        }
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
        border-color: var(--form-field-focus-border-color);
    }

    // Antd Button
    .ant-btn {
        border-radius: 4px;
        padding: 9px 10px;
        height: auto;
        line-height: 1;
        min-height: auto;

        

        &.ant-btn-text {
            font-size: var(--font-size-md);
        }

        &.ant-btn-link {
            color: var(--btn-link-text-color);
        }

        &.ant-btn-sm {
            min-height: auto;
            height: auto;
            font-size: var(--font-size-md);
            padding: 6px 7px;
        }
        
        &-default {
            font-size: var(--font-size-md);
        }
        
        &-primary {
            background-color: var(--btn-primary-bg);
            color: var(--btn-primary-text-color);
            font-size: var(--font-size-md);
            box-shadow: var(--btn-primary-box-shadow);
            border: 1px solid var(--btn-primary-bg);

            &:not(:disabled):not(.ant-btn-disabled):hover {
                background-color: var(--btn-primary-bg-hover);
            }
        }

        &.btn-secondary {
            background-color: var(--green-color-100);
            border: 1px solid var(--green-color-100);
            color: var(--white);

            &:not(:disabled):not(.ant-btn-disabled):hover {
                background-color: var(--green-color-50);
                color: var(--white);
                border-color: var(--green-color-50);
                -webkit-transition: background-color 200ms linear;
                -ms-transition: background-color 200ms linear;
                transition: background-color 200ms linear;
            }

            &.btn-disabled {
                cursor: not-allowed;
            }
        }

        &-text {
            color: var(--btn-link-text-color);

            &:not(:disabled):not(.ant-btn-disabled):hover {
                background: var(--btn-link-hover-bg);
                color: var(--btn-link-text-color);
            }
        }
    }

    .ant-btn-default{
        color: var(--blue-color);
        border-color: var(--blue-color);
        &:not(:disabled):not(.ant-btn-disabled):hover {
            background: var(--btn-link-hover-bg);
            color: var(--btn-link-text-color);
        }
    }

    // Antd Form
    .ant-form-item {
        margin-bottom: 12px;

        .ant-form-item-explain-error {
            font-size: var(--font-size-sm);
            padding: 3px 0 5px;
        }
    }

    // Antd Notification Style
    .ant-notification .ant-notification-notice {
        font-size: var(--font-size-md);
        padding: 15px 30px 15px 15px;
        border-radius: 6px;
        width: auto;

        &-success {
            background: var(--notification-success-bg);
            color: var(--notification-success-text-color);
        }

        &-error {
            background: var(--notification-error-bg);

            .ant-notification-notice-with-icon .ant-notification-notice-message, .ant-notification-notice-close {
                color: var(--notification-error-text-color);

                &:hover {
                    color: var(--notification-error-text-color);
                }
            }
        }

        .ant-notification-notice-icon-success.anticon {
            color: var(--notification-success-icon-color);
            top: 13px;
        }

        .ant-notification-notice-icon-error.anticon {
            color: var(--notification-error-icon-color);
        }

        .ant-notification-notice-close {
            top: 15px;
        }

        .ant-notification-notice-with-icon .ant-notification-notice-message {
            font-size: var(--font-size-md);
            margin-bottom: 0;
        }
    }

    //antd spiner
    .ant-spin-nested-loading {
        position: static;
    }
    .ant-spin-nested-loading >div>.ant-spin-lg .ant-spin-text{
        text-shadow: 0 0 0;
        padding-top: 30px;
    }

    .ant-popover {
        .ant-popover-inner {
            box-shadow: var(--popover-box-shadow);
            border: 1px solid var(--gray-250);
        }

        &-placement-bottomLeft &-arrow {
            z-index: 1050;
            top: 1px;

            &::after {
                width: 10px;
                height: 10px;
                border: 1px solid var(--gray-250);
            }
        }
    }

    .save-criteria-popover {
        max-width: 33vw;
        width: 100%;

        .ant-popover-arrow {
            top: -16px;
        }
        .ant-popover-inner {
            margin-top: -17px;

            padding: 10px 20px 20px;
        }
    }

    .export-popover, .configure-column-popover, .filter-popover {
        max-width: 24.7vw;
        width: 100%;

        .ant-popover-inner {
            top: -5px;
            border-radius: 0;
            width: 100%;
            position: relative;
            right: -1px;
            padding-top: 5px;
            padding-bottom: 10px;

            .ant-input::placeholder {
                color: var(--gray-color);
                opacity: .95;
            }
    
            .ant-input::-ms-input-placeholder {
                color: var(--gray-color);
                opacity: .95;
            }

            &::before {
                content: '';
                position: absolute;
                width: 8.233vw;
                height: 2px;
                background: #ffff;
                top: -1px;
                left: 8.1vw;

                @media screen and (min-width: 1920px) {
                    width: 6.5vw;
                    left: 6.4vw;
                }
            }
        }

        @media screen and (min-width: 1920px) {
            max-width: 19.5vw;
        }
    }

    .filter-popover.single-filter-column-popover {
        max-width: 15.7vw;

        @media screen and (min-width: 1600px) {
            .ant-checkbox-wrapper {
                margin-bottom: 8px;
            }

            .ant-checkbox {
                & + span {
                    padding-top: 0;
                }

                .ant-checkbox-inner {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &.popover-lg {
            max-width: 20vw;

            .category-data-wrapper {
                max-height: 36vh;
            }
        }

        &.ant-popover-placement-bottomRight {
            .ant-popover-arrow {
                top: -4px;
            }
        }

        &.ant-popover-placement-topRight {
            .ant-popover-arrow {
                bottom: 6px;
            }
        }

        .ant-popover-inner {
            &::before {
                content: none;
            }
        }
    }

    .filter-popover {
        max-width: 550px;

        .ant-popover-inner {
            &::before {
                left: auto;
                right: 0;
            }
        }
    }
    
    .configure-column-popover {
        .ant-popover-inner {
            left: 0;
        
            &::before {
                left: 0;
            }
        }
    }

    //Antd RangePicker
    .ant-picker-footer .ant-picker-ranges{
        padding-inline-start: 0px;
        margin-block-start: 0px;
    }

    .minWidth-auto {
        min-width: auto;
    }
    
    .disabled-btn {
        color: var(--gray-color);
    }

    //log exception search panel range picker 
    .log-exception-search-range-picker-popup .ant-picker-datetime-panel, .ant-picker-dropdown {
        font-size: var(--font-size-md);
    }
    .log-exception-search-range-picker-popup .ant-picker-date-panel .ant-picker-body{
        padding:5px;
        font-size: var(--font-size-md);
    }
    .log-exception-search-range-picker-popup .ant-picker-cell{
        padding: 2px 0;
    }
    .log-exception-search-range-picker-popup .ant-picker-year-panel .ant-picker-content,
    .log-exception-search-range-picker-popup .ant-picker-month-panel .ant-picker-content{
        max-height: 30vh;
    }

    .btn-lg-icon {
        margin-top: 2px;

        .anticon {
            font-size: var(--font-size-md);
        }
    }

    .hide-row-selection {
        .ag-pinned-left-header, .ag-pinned-left-cols-container {
            display: none;
        }
    }

    .config-list-grid {
        padding: 0 0 0 12px;
        background: var(--white);

        .ant-btn {
            border-radius: 0;
            box-shadow: none;
        }

        .anticon-search {
            font-size: var(--font-size-md);
            position: relative;
            top: -1px;
        }

        .btn-modify.ant-btn.btn-secondary {
            order: 3;
            border-right-color: var(--white);

            &:hover {
                border-right-color: var(--white);
            }
        }
    }

    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
    }

    .add-edit-config-drawer {
        .ant-drawer-content-wrapper {
            max-width: 80vw;

            @media screen and (min-width: 1921px) {
                max-width: 70vw;
            }
        }

        .ant-drawer-header {
            padding: 13px 18px;
        }

        .ant-drawer-wrapper-body {
            position: relative;

            .ant-drawer-body {
                padding-bottom: 50px;
            }
        }

        &.config-audit-history-drawer {
            .ant-drawer-content-wrapper {
                max-width: 85vw;
    
                @media screen and (min-width: 1921px) {
                    max-width: 73vw;
                }
            }
            .ant-drawer-wrapper-body {
                .ant-drawer-body {
                    padding: 10px 15px;

                    > div {
                        margin: 0;
                        height: 100%;
                    }
                }
            }
        }

        &.summary-drawer {
            z-index: 9999;

            .ant-drawer-content-wrapper {
                max-width: 85vw;

                @media screen and (min-width: 1921px) {
                    max-width: 80vw;
                }
            }

            .ant-drawer-wrapper-body {
                .ant-drawer-body {
                    padding: 0px;
                    margin-left: -10px;

                    .config-detail-wrapper {
                        padding-top: 0;
                        padding-bottom: 0;
                        box-shadow: none;

                        @media screen and (min-width: 1600px) {
                            padding-top: 10px;
                        }
                    }
                }
            }
        }

        .ant-drawer-header-title {
            flex-direction: row-reverse;

            .ant-drawer-title {
                color: var(--blue-color-750);
                font-weight: var(--font-weight-medium);
                font-size: var(--font-size-lg);
            }

            .ant-drawer-close {
                margin: 0;
                margin-right: -10px;
            }
        }
    }

    .ant-modal-confirm {
        &.confirmation-popup {
            .ant-modal-content {
                padding: 0;
                border-radius: 4px;

                @media screen and (min-width: 1600px) {
                    width: 500px;
                }
            }

            .ant-modal-close {
                top: 7px;
                inset-inline-end: 5px;

                .ant-modal-close-x {
                    font-size: var(--font-size-md);
                }
            }

            .ant-modal-confirm-body {
                display: block;

                .ant-modal-confirm-title {
                    display: block;
                    padding: 5px 10px;
                    border-bottom: 1px solid var(--gray-300);
                    color: var(--blue-color);
                    font-size: var(--font-size-lg);
                }

                .ant-modal-confirm-content {
                    padding: 5px 10px;
                    min-height: 65px;
                    color: var(--black);
                    font-size: var(--font-size-md);
                }
            }

            .ant-modal-confirm-btns {
                padding: 0 12px 12px;
                
                .ant-btn {
                    text-transform: uppercase;

                    &.ant-btn-default {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .ant-modal-confirm-body .ant-modal-confirm-title{
            font-weight: var(--font-weight-regular);
        }
    }      
    // Utility classes
    .w-100 {
        width: 100%;
    }
    .h-100 {
        height: 100%;
    }
    .p-0 {
        padding: 0!important;
    }
    .m-0 {
        margin: 0!important;
    }
    .disabled {
        cursor: not-allowed;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .mt-5 {
        margin-top: 5px!important;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mb-15 {
        margin-bottom: 15px;
    }

    .margin-5{
        margin: 5px;
    }
    .margin-10{
        margin: 10px;
    }


    .text-align-left{
        text-align: left;
    }

    //scrollbar 
    ::-webkit-scrollbar-thumb {
        background-color: #d0d0d0;
    }
    
    ::-webkit-scrollbar {
        height: 10px;
        width: 10px;
    }
    
    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    hr{
        border-top: 1px solid var(--default-border-color);
        border-width: 0px;
        border-top-width: 1px;
    }
    .display-none{
        display: none
    }

    //AGgrid
    // .ag-root-wrapper-body.ag-layout-normal{
    //     height:100%;
    // }
    // .ag-body-viewport .ag-center-cols-clipper{
    //     height: auto !important;
    // }
    // .ag-body-viewport{
    //     min-height: 50vh;
    //     max-height: 50vh;
    // }
    .ag-header{
        border-bottom-color: var(--ag-grid-header-border-bottom-color);
    }
    .ag-checkbox-input-wrapper.ag-checked:after,
    .ag-radio-button-input-wrapper.ag-checked::after {
        color: var(--blue-color);
    }
    .ag-checkbox-input-wrapper{
        font-size:15px;
    }
    .ag-theme-material{
        --ag-checkbox-unchecked-color: var(--ag-grid-unchecked-checkbox-border-color);
        --ag-font-size: var(--font-size-md);
        --ag-row-height: 30px;
        --ag-header-height: 30px;

        .ag-ltr .ag-sort-indicator-icon {
            color: var(--green-color-100);
        }

        @media screen and (min-width: 1600px) {
            --ag-row-height: 44px;
            --ag-header-height: 46px;
        }
    }
    .log-level-error-row-style .ag-cell.ag-cell-last-left-pinned{
        position: relative;

        &::before {
            content: '';
            border-left : 4px solid var(--error-color);
            position: absolute;
            left: 0;
            height: 100%;
        }
    }

    .ag-grid-table-loader-text{
        color: var(--gray-700);
        font-size:var(--font-size-md);
    }

    .collapsible-form, .add-edit-config-drawer .collapsible-form {
        .ant-collapse.ant-collapse-ghost > .ant-collapse-item {
            &:not(.ant-collapse-item-active) {
                .ant-collapse-arrow {
                    svg {
                        transform: rotate(270deg); 
                    }
                }
            }

            .ant-collapse-content >.ant-collapse-content-box {
                padding: 0;
                padding-top: 5px;
            }

            > .ant-collapse-header {
                position: relative;
                top: -37px;
                right: -10px;
                height: 0;
                padding: 0;

                .ant-collapse-expand-icon {
                    width: 20px;
                    height: 20px;
                    background: var(--blue-color-100);
                    color: var(--white);
                    border-radius: 50%;
                    padding: 4px;
                }
            }
        }

        .ant-form-item {
            margin-bottom: 25px;
        }
    }

    .form-lg {
        @media screen and (min-width: 1600px) {
            max-width: 1600px;
        }
    }

    .ant-tooltip {
        .ant-tooltip-inner, .ant-tooltip-arrow:before {
            background: rgba(0, 0, 0, .81);
        }

        &.ant-tooltip-placement-bottom {
            .ant-tooltip-arrow {
                top: 1px!important;
            }
        }

        .ant-tooltip-inner {
            padding: 10px;
            font-size: var(--font-size-md);
        }

        @media screen and (min-width: 1600px) {
            max-width: 345px;
        }
    }

    //
    a{
        cursor: pointer;
    }

    .text-wrap-break-word{
        word-wrap: break-word;
    }

    .error-text {
        font-size: var(--font-size-md);
        color: var(--red-50);
        position: relative;
        top: 5px;
    }

    .text-wrap-ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }

    .status-btn {
        display: inline-block;
        padding: 6px;
        border-radius: 5px;
        position: relative;
        line-height: 1.2;
        top: -2px;
        color: var(--white);
        font-weight: var(--font-weight-regular);
        text-transform: capitalize;
        font-size: var(--font-size-md);
    }

    div.react-dropdown-select-dropdown {
        z-index: 9999;
    }

    .status-grid-btn, .status-btn {
        &.resolved-btn, &.completed-btn {
            background: var(--replay-resolved-color);
        }
    
        &.running-btn, &.acitve-btn {
            background: var(--replay-progress-color);
        }
    
        &.scheduled-btn {
            background: var(--replay-schedule-color);
        }
    
        &.pending-btn {
            background: var(--replay-pending-color);
        }
    
        &.error-btn {
            background: var(--replay-error-color);
        }
    }

    .user-managemnet-list{
        margin:0px;
    }

    @keyframes fadeIn {  
        from {  
            opacity:0;  
        }  
     
        to {  
            opacity:1;  
        }  
    }

    @keyframes fadeOut {  
        from {  
            opacity:1;  
        }  
     
        to {  
            opacity:0;  
        }  
    }

    @keyframes slideDown {  
        from {  
            height: 0%;
        }  
     
        to {  
            height:100%;
        }  
    }

    .justify-content-between{
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1600px) {
        html {
            font-size: var(--font-size-xl);
        }
    }

    @media screen and (max-width: 1440px) {
        html {
            font-size: var(--font-size-md);
        }

        .ant-select {
            &-dropdown .ant-select-item {
                min-height: 27px;
            }
        }
    }
`;

export default GlobalStyle;
