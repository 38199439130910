import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const HeaderWrapper = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  background: var(--header-bg);
  padding: 10px 25px;
  line-height: 1;
  height: auto;
  color: var(--white);

  &.ant-layout-header {
    box-shadow: 0 1px 0px var(--black-350);
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
  }

  .ant-menu-light .ant-menu-item, .ant-menu-light .ant-menu-submenu-title {
    &:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
      color: var(--white);
    }
  }

  .ant-menu-light.ant-menu-horizontal {
    & >.ant-menu-item::after, & >.ant-menu-submenu::after {
      border: none;
    }

    & > .ant-menu-item-selected,
    & > .ant-menu-submenu-selected >.ant-menu-submenu-title {
      font-weight: var(--font-weight-semibold);
      color: var(--header-menu-active-color);

      @media screen and (max-width: 991px) {
        color: var(--blue-color-100);
      }
    }

    & >.ant-menu-item-selected::after,
    & >.ant-menu-item:hover::after,
    & >.ant-menu-submenu:hover::after,
    & >.ant-menu-submenu-open::after,
    & >.ant-menu-submenu-selected::after {
      background-color: var(--header-menu-active-border);
      border: none;
      position: absolute;
      height: 5px;
      width: 85%;
      left: 50%;
      bottom: 1%;
      transform: translateX(-50%);

      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &.ant-layout-header {
      padding: 10px;
    }
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  max-width: 140px;

  @media screen and (max-width: 991px) {
    padding-left: 50px;
  }
`;

export const LogoImg = styled.img`
  vertical-align: middle;
  width: 100%;
`;

export const AntdMenu = styled(Menu)`
  border-bottom: none;
  background: transparent;
  color: var(--white);
  font-size: var(--font-size-md);

  &.ant-menu.ant-menu-root {
    align-self: stretch;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.display-none {
      display: none;
    }
  }

  .ant-menu-item, .ant-menu-submenu {
    position: relative;
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  .ant-menu-submenu-arrow {
    transform: rotate(90deg);
    display: inline-block;
    position: static;
    vertical-align: middle;
    margin-left: 5px;
  }

  @media screen and (max-width: 991px) {
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 175px;
    height: 100vh;
    left: 0;
    background: var(--header-bg);
    z-index: 999;
    top: 53px;
  }
`;

export const Hamburger = styled(MenuOutlined)`
  font-size: 24px;
  display: none;

  @media screen and (max-width: 991px) {
    position: absolute;
    display: inline-block;
    left: 10px;
    top: 23px;
  }
`;

export const TimeDropdownArrowWrapper = styled.span`
  .anticon.anticon-down{
    font-size: var(--font-size-sm);
    margin-left: 4px;
    top: 1px;
  }
`;