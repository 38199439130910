import * as types from '../ActionTypes/commonConfigTypes';

interface UpdateTimeZoneAction {
  type: string
  timeZone: string
}

interface UpdateLoadingStatusAction {
  type: string
  loadingCount: number
}

export function updateTimeZone (timeZone: string): UpdateTimeZoneAction {
  return {
    type: types.UPDATE_TIMEZONE,
    timeZone
  };
}

export function updateDataLoadingStatus (loadingCount: number): UpdateLoadingStatusAction {
  return {
    type: types.UPDATE_LOADING_STATE,
    loadingCount
  };
}
