import React from 'react';
import type { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getApplicationDetails,
  getAllExceptionCategory,
  getAllExceptionSeverity,
  getAllExceptionType
} from '../../redux/actions/Admin/applicationConfigActions';
import LogExceptionSearch from '../../pages/LogExceptionSearch';

const ExceptionSearchContainer = (): ReactElement => {
  const applicationList = useSelector((state: any) => state.applicationConfig.applicationConfigList);
  const activeTab = useSelector((state: any) => state?.exceptionSearchReducer?.activeTab);
  const userAccessInfo = useSelector((state: any) => state.userAccess.userAccessInfo);
  const timeZone = useSelector((state: any) => state.commonConfig.timeZone);
  const categoryList = useSelector((state: any) => state?.listConfig?.categories);
  const severityList = useSelector((state: any) => state?.listConfig?.severities);
  const typeList = useSelector((state: any) => state?.listConfig?.types);
  const exceptionTabList = useSelector((state: any) => state?.exceptionSearchReducer?.exceptionTabList);

  const dispatch = useDispatch();

  const fetchAppplicationList = (): void => {
    dispatch(getApplicationDetails());
  };

  const fetchExceptionCategoryList = (): void => {
    dispatch(getAllExceptionCategory());
  };

  const fetchExceptionSeverityList = (): void => {
    dispatch(getAllExceptionSeverity());
  };

  const fetchExceptionTypeList = (): void => {
    dispatch(getAllExceptionType());
  };

  return (
    <LogExceptionSearch
      activeTab={activeTab}
      applicationList={applicationList}
      categoryList={categoryList}
      severityList={severityList}
      userAccessInfo={userAccessInfo}
      timeZone={timeZone}
      fetchAppplicationList={fetchAppplicationList}
      fetchExceptionCategory={fetchExceptionCategoryList}
      fetchExceptionSeverity={fetchExceptionSeverityList}
      fetchExceptionType={fetchExceptionTypeList}
      typeList={typeList}
      logExceptionType="Exception"
      searchTabList={exceptionTabList}
    />
  );
};

export default ExceptionSearchContainer;
