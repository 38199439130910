import React, { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { Button, Col, Form, Row, Select, Switch } from 'antd';
import dayjs from 'dayjs';
import {
  AppstoreOutlined,
  CalendarOutlined,
  HourglassOutlined,
  ProfileOutlined,
  SearchOutlined
} from '@ant-design/icons';
import MultiSelect from '../../../common/MultiSelect';
import CustomSearchInput from '../../../common/CustomSearchInput';
import { applicationDataHelper } from '../../../../helpers/applicationDataHelper';
import { logFields } from '../../../../constants/FormFields/SearchForm/logField';
import { exceptionFields } from '../../../../constants/FormFields/SearchForm/exceptionField';
import textConstants from '../../../../constants/textConstants';
import FloatingLabel from '../../../common/FloatingLabel';
import { checkIfDateRangeIsValid, validateSearchQuery } from './validator';
import {
  ApplicationNameWrapper,
  CalendarButtonGroupWrapper,
  FormRow,
  LogExceptionDateTimeLabel,
  LogExceptionDateTimeWrapper,
  LogExceptionSearchPanelWrapper,
  LogExceptionTypeWrapper,
  SearchButton,
  SearchInputWrapper,
  SearchFormPanelWrapper,
  SearchAdvanceForm,
  SearchAdvanceFormWrapper,
  SearchAdvanceFormButtonWrapper,
  CalendarItemWrapper
} from './style';

import AntdRangePicker from '../../../common/RangePicker';
import { CalendarIconButton } from '../../../common/Buttons/style';
import CalendarPopOver from '../../popOver/CalendarPopOver';
import { checkIfSelectedApplicationHaveSameTableName, checkIfDataIsJSON, getItemFromArrayOfObject } from '../../../../helpers/commonHelper';
import {
  logLevelOptions,
  exceptionStatusOptions
} from '../../../../constants/DropDownOptions/logExceptionSearch';
import {
  generateCustomInputSearchStringFromFormObject,
  getSearchQuerySuggestionList
} from '../../../../helpers/ServiceHelpers/logExceptionSearchServiceHelper';
import { logExceptionDefaultFormValues } from '../../../../constants/FormDefaultValues/LogExceptionSearch';
import { type FormFieldType } from '../../../../constants/Interfaces/FormFieldTypes';

interface FieldOption {
  label: string
  value: string
}

interface LogExceptionSearchPanelProps {
  applicationList: any
  fetchAppplicationList: any
  userAccessInfo?: any
  logExceptionSearchType?: string
  timeZone: string
  searchDataHandler: any
  initialSearchCriteria: any
}

const LogExceptionSearchPanel: FC<LogExceptionSearchPanelProps> = ({
  applicationList,
  fetchAppplicationList,
  logExceptionSearchType,
  timeZone,
  userAccessInfo,
  searchDataHandler,
  initialSearchCriteria
}) => {
  const fieldDataArr =
    logExceptionSearchType === 'Log'
      ? logFields
      : exceptionFields;
  const ignoreFieldKeys = [
    'applicationName',
    'logLevel',
    'dateTime',
    'formSearchMode',
    'exceptionStatus',
    'appName'
  ];
  const [form] = Form.useForm();
  const suggestionList = getSearchQuerySuggestionList(form.getFieldValue('applicationName'), ignoreFieldKeys, logExceptionSearchType ?? '');

  const [isFormValid, setIsFormValid] = useState(true);
  const [isAdvanceFormVisible, setAdvanceFormVisibility] = useState(false);
  const [isCustomSearchMode, toggleCustomSearchMode] = useState(true);
  const [isCustomSearchModeActive, setCustomSearchModeStatus] = useState(false);
  const [applicationNameList, setApplicationNameList] = useState([]);
  const [popOverOpenType, setPopOverOpenType] = useState<string | null>();
  const [dateQuickSelectValue, setDateQuickSelectValue] = useState<string | null>('Last 4 Hours');
  const [categoryList, setCategoryList] = useState<any>([]);
  const [typeList, setTypeList] = useState<any>([]);
  const [severityList, setSeverityList] = useState<any>([]);
  const [initialFormValue, setInitialFormValue] = useState(logExceptionDefaultFormValues);
  const [isDateTimeHoursRestriction, setDateTimeHoursRestriction] = useState(false);

  const getApplicationExceptionDataByParam = (param: string): any[] => {
    return applicationDataHelper.getDataFromApplicationList(
      form.getFieldValue('applicationName') && form.getFieldValue('applicationName'),
      `${param}Name`
    );
  }

  const onApplicationNameChange = (): void => {
    setCategoryList(getApplicationExceptionDataByParam('exceptionCategory'));
    setSeverityList(getApplicationExceptionDataByParam('exceptionSeverity'));
    setTypeList(getApplicationExceptionDataByParam('exceptionType'));
  }

  useEffect(() => {
    if (applicationList && applicationList.length === 0 && !sessionStorage.getItem('applicationDetails')) {
      fetchAppplicationList();
    }
  }, [applicationList]);

  useEffect(() => {
    let filteredApplicationList: any = [];
    if (userAccessInfo?.adminAccess === 'Y') {
      filteredApplicationList = applicationDataHelper.getApplicationNames(applicationList);
    } else {
      filteredApplicationList = applicationDataHelper.getApplicationNamesForUser(
        userAccessInfo,
        applicationList
      );
    }

    setApplicationNameList(filteredApplicationList);
  }, [applicationList, userAccessInfo]);

  useEffect(() => {
    const searchCriteria = checkIfDataIsJSON(initialSearchCriteria) ? JSON.parse(initialSearchCriteria) : null;
    if (searchCriteria && Object.keys(searchCriteria).length > 0) {
      resetFormData();
      let initialSearchQuery = {
        ...searchCriteria
      }
      if (logExceptionSearchType?.toLowerCase() === 'exception') {
        const exceptionStatusArr = initialSearchQuery.exceptionStatus || [];
        const exceptionStatusValue = exceptionStatusOptions.filter((item: any) => exceptionStatusArr.includes(item.value));
        initialSearchQuery = {
          ...initialSearchQuery,
          exceptionStatus: exceptionStatusValue
        }
      }
      setInitialFormValue({
        ...initialSearchQuery,
        applicationName: initialSearchQuery?.appName
      });
      setDateQuickSelectValue(null);
      form.setFieldsValue({
        ...initialSearchQuery,
        applicationName: initialSearchQuery?.appName
      })
      const formValues = {
        ...initialSearchQuery,
        applicationName: initialSearchQuery?.appName,
        searchFieldValue: generateCustomInputSearchStringFromFormObject(initialSearchQuery, fieldDataArr, ['appName', ...ignoreFieldKeys])
      };
      setInitialFormValue(formValues);
      // setDateQuickSelectValue(null);
      form.setFieldsValue(formValues);
    } else if (searchCriteria === null) {
      setDateQuickSelectValue('Last 4 Hours');
      form.setFieldsValue(logExceptionDefaultFormValues);
      setInitialFormValue(logExceptionDefaultFormValues)
    }
  }, [initialSearchCriteria]);

  const handleSearchMode = (isEnabled: boolean): void => {
    setAdvanceFormVisibility(isEnabled);
    toggleCustomSearchMode(!isEnabled);
  };

  const getOptionList = (name: string): FieldOption[] => {
    if (name === 'exceptionCategory' || name === 'exceptionSeverity' || name === 'exceptionType') {
      return getApplicationExceptionDataByParam(name);
    } else {
      return suggestionList[name];
    }
  };

  const renderSearchFields = (fields: FormFieldType[]): ReactNode => {
    return fields.map((item: FormFieldType) => {
      return (
        <Col key={item.id} xs={24} md={8} xxl={6}>
          <Form.Item name={item.name}>
            <FloatingLabel
              label={item.label}
              type={item.type}
              id={item.id}
              // {...(item.mode && { mode: item.mode })}
              {...((item.type === 'select' || item.type === 'tagInput') && { options: getOptionList(item.name) })}
            />
          </Form.Item>
        </Col>
      );
    });
  };

  const handleDetailFormSubmit = (): void => {
    const isAdvanceSearchMode = form.getFieldValue('formSearchMode');
    const formObj: any = form.getFieldsValue();

    const filteredObj = Object.keys(formObj).reduce((obj: string, key, counter) => {
      const commaVal = counter === Object.keys(formObj).length - 1 ? '' : '; ';

      if (key && key !== 'searchFieldValue' && formObj[key] && !ignoreFieldKeys.includes(key)) {
        const fieldName: any = fieldDataArr.find((item: any) => item.name === key);
        obj += `${String(fieldName?.label)}= ${String(formObj[key])}${commaVal}`;
      }
      return obj;
    }, '');

    form.setFieldValue('searchFieldValue', filteredObj.replace(/,\s*$/, ''));
    if (isAdvanceSearchMode) {
      onFormSubmit(formObj, false);
    }
  };

  const resetFormData = (): void => {
    // setSearchParam({});
    const formObj = form.getFieldsValue();
    const filteredFormObj = Object.keys(formObj).reduce((obj: any, key) => {
      if (key && formObj[key] && !ignoreFieldKeys.includes(key)) obj[key] = typeof formObj[key] === 'string' ? '' : [];
      return obj;
    }, {});

    form.setFieldsValue({
      ...filteredFormObj
    });
  };

  const onFormSubmit = (values: any, isPanelSubmit: boolean = true): void => {
    const { applicationName, formSearchMode, searchFieldValue, exceptionStatus, ...formData } = values;
    form.setFieldValue('formSearchMode', false);
    if (isPanelSubmit) {
      handleDetailFormSubmit();
    }

    const appNames = applicationName?.reduce((prevVal: string, currVal: { value: string }, id: number) => {
      return id === 0 ? currVal.value : `${prevVal},${currVal?.value}`;
    }, '');

    setAdvanceFormVisibility(false);
    toggleCustomSearchMode(true);
    searchDataHandler({
      ...formData,
      appName: applicationName,
      ...(logExceptionSearchType === 'Exception' && { exceptionStatus: getItemFromArrayOfObject(form.getFieldValue('exceptionStatus'), 'label') }),
      applicationName: appNames
    });
  };

  const setFormDate = (
    isQuickSelect: boolean,
    selectedValueString: string,
    selectedDateRange: any
  ): void => {
    if (isQuickSelect) {
      setDateQuickSelectValue(selectedValueString);
    } else if (!isQuickSelect) {
      setDateQuickSelectValue(null);
    }
    if (selectedDateRange && selectedDateRange.length === 2) {
      form.setFieldValue('dateTime', [dayjs(selectedDateRange[0]), dayjs(selectedDateRange[1])]);
    }
    checkFormValidity({ dateTime: selectedDateRange })
  };

  const checkFormValidity = (changedValues?: any): void => {
    if (changedValues?.applicationName) {
      let isSingleDayTimeAllowed = false;
      if (changedValues.applicationName.length > 1) {
        const isApplicationListFromSameTable: boolean = checkIfSelectedApplicationHaveSameTableName(changedValues.applicationName, logExceptionSearchType);
        if (!isApplicationListFromSameTable) {
          isSingleDayTimeAllowed = true
          void form.validateFields(['dateTime']);
        }
      }
      setDateTimeHoursRestriction(isSingleDayTimeAllowed)
    }

    form.validateFields().then((v) => {
      setIsFormValid(true);
    }).catch(e => {
      if (e?.errorFields?.length > 0) {
        setIsFormValid(false);
      } else {
        setIsFormValid(true);
      }
    });
  }

  const onSubmitSearchFormHandler = (): void => {
    form.validateFields().then((values) => {
      setIsFormValid(true);
      onFormSubmit(values);
    }).catch(e => {
      setIsFormValid(false);
    })
  }

  return (
    <LogExceptionSearchPanelWrapper>
      <Form
        form={form}
        id={`${logExceptionSearchType?.toLowerCase() ?? 'logExceptionType'}-search-form`}
        onValuesChange={(changedValues) => { void checkFormValidity(changedValues) }}
        initialValues={initialFormValue}
        onFinish={onSubmitSearchFormHandler}
        onFinishFailed={(formData: any) => {
          if (formData?.errorFields?.length > 0) {
            setIsFormValid(false);
          }
        }}
        scrollToFirstError
      >
        <FormRow gutter={[0, 0]}>
          <ApplicationNameWrapper>
            <Form.Item
              name="applicationName"
              rules={[{ required: true, message: 'Application is mandatory!' }]}
            >
              <MultiSelect
                dropdownPanelSize="large"
                options={applicationNameList}
                placeholder="Applications"
                fieldCustomIcon={<AppstoreOutlined />}
                onDropdownClose={onApplicationNameChange}
                fieldName="Application"
                searchInputPlaceholder=""
                showDoneButton
                searchable
                multi
              />
            </Form.Item>
          </ApplicationNameWrapper>
          <SearchFormPanelWrapper>
            <SearchInputWrapper
              onFocus={() => setCustomSearchModeStatus(true)}
              onBlur={() => checkFormValidity()}
              data-testid="logExceptionSearchWrapper"
            >
              <Form.Item
                name="searchFieldValue"
                rules={
                  [
                    () => ({
                      async validator (_, value) {
                        const errorMessage = validateSearchQuery(value, fieldDataArr);
                        if (errorMessage.length > 0) {
                          return await Promise.reject(errorMessage);
                        }

                        return await Promise.resolve();
                      }
                    })
                  ]
                }
              >
                <CustomSearchInput
                  form={form}
                  isDisabled={!isCustomSearchMode}
                  ignoreFieldList={ignoreFieldKeys}
                  key={form?.getFieldValue('applicationName')?.toString() || ''}
                  resetData={resetFormData}
                  suggestionData={{
                    field: [...fieldDataArr],
                    ...suggestionList,
                    ...(
                      logExceptionSearchType === 'Exception' && ({
                        exceptionCategory: categoryList,
                        exceptionSeverity: severityList,
                        exceptionType: typeList
                      })
                    )
                  }}
                  setCustomSearchModeStatus={(status: boolean) => setCustomSearchModeStatus(status)}
                />
              </Form.Item>
            </SearchInputWrapper>
            <Form.Item
              className="switch-form-item"
              name="formSearchMode"
              initialValue={false}
              valuePropName="checked"
            >
              <Switch size="small" onChange={handleSearchMode} />
            </Form.Item>

          </SearchFormPanelWrapper>
          {!isCustomSearchModeActive && (
            <LogExceptionTypeWrapper>
              <ProfileOutlined />
              {logExceptionSearchType === 'Log' && (
                <Form.Item name="logLevel" initialValue={' '}>
                  <Select
                    options={logLevelOptions}
                  />
                </Form.Item>
              )}
              {logExceptionSearchType === 'Exception' && (
                <Form.Item name="exceptionStatus">
                  <MultiSelect
                    options={exceptionStatusOptions}
                    placeholder=""
                    fieldCustomIcon={null}
                    fieldName="Exception Status"
                    searchInputPlaceholder=""
                    visibleTagCount={1}
                    disableSearch={false}
                    hasSelectAll
                    showSelectedTags={false}
                    multi
                  />
                </Form.Item>
              )}
            </LogExceptionTypeWrapper>
          )}
          <LogExceptionDateTimeWrapper>
            <CalendarButtonGroupWrapper>
              <CalendarIconButton
                icon={<CalendarOutlined />}
                className={dateQuickSelectValue === null ? 'active' : ''}
                onClick={() => {
                  setDateQuickSelectValue(null);
                  setPopOverOpenType(
                    popOverOpenType === 'CalendarPicker' ? null : 'CalendarPicker'
                  );
                }}
              />
              <CalendarPopOver
                contentType={'QuickPicker'}
                isPopOverOpen={popOverOpenType === 'QuickPicker'}
                closePopOver={() => setPopOverOpenType(null)}
                handleOpenChange={() => setPopOverOpenType(null)}
                setDate={setFormDate}
              >
                <CalendarIconButton
                  data-testid="testid-quickPicker-btn"
                  icon={<HourglassOutlined />}
                  className={dateQuickSelectValue !== null ? 'active' : ''}
                  onClick={() =>
                    setPopOverOpenType(popOverOpenType === 'QuickPicker' ? null : 'QuickPicker')
                  }
                />
              </CalendarPopOver>
              <CalendarItemWrapper>
                <div className={dateQuickSelectValue === null ? 'display-none' : ''}>
                  <Form.Item name="dateTime"
                    rules={[{ validator: async (_, values) => await checkIfDateRangeIsValid(_, values, isDateTimeHoursRestriction) }]}>
                    <LogExceptionDateTimeLabel className="cursor-pointer" onClick={() =>
                      setPopOverOpenType(popOverOpenType === 'QuickPicker' ? null : 'QuickPicker')
                    }>
                      {dateQuickSelectValue && <>{dateQuickSelectValue}</>}
                    </LogExceptionDateTimeLabel>
                  </Form.Item>
                </div>
                <div className={dateQuickSelectValue === null ? '' : 'display-none'}>
                  <Form.Item name="dateTime"
                    rules={[{ validator: async (_, values) => await checkIfDateRangeIsValid(_, values, isDateTimeHoursRestriction) }]}>
                    <AntdRangePicker
                      key={'dateTime'}
                      openPanel={dateQuickSelectValue === null}
                      showBorder={false}
                      setDate={setFormDate}
                      formRef={form}
                    />
                  </Form.Item>
                </div>
              </CalendarItemWrapper>
            </CalendarButtonGroupWrapper>
          </LogExceptionDateTimeWrapper>
          <SearchButton
            // onClick={onSubmitSearchFormHandler}
            type="primary"
            data-testid="submitSearchBtn"
            htmlType="submit"
            icon={<SearchOutlined />}
          />
        </FormRow>
        {
          (!isFormValid || form.getFieldError('searchFieldValue').length > 0) &&
          <>
            &nbsp;
          </>
        }
        <SearchAdvanceFormWrapper>
          <SearchAdvanceForm className={isAdvanceFormVisible ? 'slide-in' : 'slide-out'}>
            <Row gutter={16}>
              {renderSearchFields(logExceptionSearchType === 'Log' ? logFields : exceptionFields)}
            </Row>
            <SearchAdvanceFormButtonWrapper>
              <Button type="text" className="mr-10 btn-regular" onClick={resetFormData}>
                {textConstants.RESET_BUTTON_LABEL}
              </Button>
              <Button
                className='btn-regular'
                type="primary"
                onClick={handleDetailFormSubmit}
                data-testid="submitAdvanceFormBtn"
              >
                {textConstants.SEARCH_BUTTON_LABEL}
              </Button>
            </SearchAdvanceFormButtonWrapper>
          </SearchAdvanceForm>
        </SearchAdvanceFormWrapper>
      </Form>
    </LogExceptionSearchPanelWrapper >
  );
};

export default React.memo(LogExceptionSearchPanel);
